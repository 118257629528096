<template>

<app-content-box title="Connect" icon="connect" :transparent="true">

	<p v-if="!isConnected" class="connect">Connect your BGG username to get buddy recommendations and more.</p>

	<app-button v-if="!isConnected" v-on:click="onConnectClick" theme="blue" text="Connect" :fullWidth="true" />

	<div v-if="isConnected" class="connect-user">

		<div class="connect-user-avatar" v-bgimage="$store.getters['session/bgg'].avatar" />
		<div class="connect-user-name">{{ $store.getters['session/bgg'].name }}<small>{{ $store.getters['session/bgg'].username }}</small></div>

	</div>

	<app-button v-if="isConnected" :loading="is.disconnecting" v-on:click="onDisconnectClick" theme="blue" text="Disconnect" :fullWidth="true" />

	<com-connect v-if="is.connecting" v-on:close="is.connecting = false" />

</app-content-box>

</template>

<script>

import comConnect from '../common/panel/Connect'

export default {

	components: {
		'com-connect': comConnect
	},

	data: function() {

		return {
			is: {
				connecting: false,
				disconnecting: false
			}
		}

	},

	computed: {

		isConnected: function() {

			return this.$store.getters['session/bgg']

		}

	},

	methods: {

		onConnectClick: function() {

			this.is.connecting = true
			
		},

		onDisconnectClick: function() {
			
			this.is.disconnecting = true

			this.$api.delete('session/bgg').then(function() {

				this.is.disconnecting = false
				
			}.bind(this), function() {

				this.is.disconnecting = false

			}.bind(this))

		}
		
	}

}

</script>

<style scoped>

.connect {
	font-size: 16px;
	line-height: 20px;
	color: #666;
	margin-bottom: 20px;
}

.connect-user {
	display: flex;
	align-items: center;
	background-color: #fff;
	padding: 10px;
	border: 1px solid #eee;
	border-radius: 4px;
	margin-bottom: 20px;
}

.connect-user-avatar {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	background-position: 50% 50%;
	background-size: cover;
	flex-shrink: 0;
	margin-right: 10px;
}

.connect-user-name {
	font-size: 16px;
	color: #777;
}

.connect-user-name small {
	font-size: 14px;
	font-weight: bold;
	display: block;
	margin-top: 4px;
}

</style>
